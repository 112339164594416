import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    Output,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { Animations } from '../shared/beaconstac-animations';
import { Utils } from 'app/shared/utils';
import { HelpDocsService, SearchItem, SearchService } from '../global-services/search.service';
import { EMPTY, Subject, Subscription, of } from 'rxjs';
import { catchError, debounceTime, finalize, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../global-services/auth.service';
import { AMPLITUDE_EVENTS, AMPLITUDE_EVENT_CATEGORIES, AmplitudeService } from 'app/global-services/amplitude.service';

interface HelpDocResult {
    id: number;
    title: string;
    description: string;
    url: string;
}

@Component({
    selector: 'beaconstac-global-search',
    templateUrl: './global-search.component.html',
    styleUrls: ['./global-search.scss'],
    animations: [Animations.collapse, Animations.rotate],
})
export class GlobalSearchComponent implements AfterViewInit, OnDestroy {
    @Input() isReseller: boolean;
    @ViewChildren('lastItem') lastItems: QueryList<ElementRef>;
    @Output() onToggleSearchBar = new EventEmitter<boolean>();

    searchText = '';
    searchInputSubject = new Subject<any>();
    showSearch: boolean = false;

    isProcessingSearch: boolean = false;
    isProcessingDocs: boolean = false;
    searchError: string | null = null;
    totalSearchResults: number = 0;
    searchRequest: Subscription;
    searchResults: Array<SearchItem> = [];
    hasFirstSearchDone: boolean = false;

    helpDocsRequest: Subscription;
    helpDocResults: HelpDocResult[] = [];

    helpDocResultsCache: Map<string, Array<{}>> = new Map<string, Array<{}>>();
    helpDocsStatic = [
        {
            id: '-1',
            title: 'How to create your first QR code?',
            url: 'https://docs.uniqode.com/en/articles/2833359-how-to-create-your-first-qr-code',
        },
        {
            id: '-2',
            title: 'How to create QR codes in bulk?',
            url: 'https://docs.uniqode.com/en/articles/3180578-how-to-create-qr-codes-in-bulk',
        },
        {
            id: '-3',
            title: 'How to design custom QR Codes?',
            url: 'https://docs.uniqode.com/en/articles/3313766-how-to-design-custom-qr-codes',
        },
    ];
    recentSearches: Array<string> = [];
    Utils: any = Utils;
    seeMoreClicked: boolean = false;
    isMobileDevice: boolean = window.innerWidth < 768;

    isFilterByDropdownOpen: boolean = false;
    filterBy = { name: 'All', value: 'all' };
    filterByOptions = [
        { name: 'All', value: 'all' },
        { name: 'GS1 Codes', value: 'gs1_code' },
        { name: 'QR Codes', value: 'qr_code' },
        { name: 'Linkpages', value: 'linkpage' },
        { name: 'Forms', value: 'form' },
        { name: 'Landing Pages', value: 'landing_page' },
    ];

    isSortByDropdownOpen: boolean = false;
    sortBy = { name: 'Last Updated', value: '-updated' };
    sortByOptions = [
        { name: 'Last Created', value: '-created' },
        { name: 'Last Updated', value: '-updated' },
        { name: 'Name (A-Z)', value: 'name' },
    ];

    constructor(
        private searchService: SearchService,
        private helpDocsService: HelpDocsService,
        public authService: AuthService,
        private amplitudeService: AmplitudeService,
    ) {}

    ngAfterViewInit(): void {
        this.searchInputSubject.pipe(debounceTime(500)).subscribe((value) => {
            this.showSearch = true;
            this.seeMoreClicked = false;
            this.filterBy = { name: 'All', value: 'all' };
            this.sortBy = { name: 'Last Updated', value: '-updated' };
            this.searchResults = [];
            this.onSearch(value);
        });
        this.lastItems?.changes?.subscribe(() => this.scrollToLastItem());
    }

    scrollToLastItem() {
        const lastItem = this.lastItems.last;
        if (lastItem) {
            lastItem?.nativeElement?.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }

    toggleDropdown() {
        this.isFilterByDropdownOpen = !this.isFilterByDropdownOpen;
        this.isSortByDropdownOpen = !this.isSortByDropdownOpen;
    }

    onSearchInput() {
        if (!this.searchText) {
            return;
        }
        if (!this.showSearch) {
            this.searchText = '';
            return;
        }
        this.searchInputSubject.next(this.searchText);
        this.clearSearchResponse();
    }

    fetchMore() {
        this.seeMoreClicked = true;
        this.amplitudeService.logEvent(AMPLITUDE_EVENT_CATEGORIES.Engagement, AMPLITUDE_EVENTS.SEARCH_SEE_MORE);
        this.onSearch(this.searchText);
        setTimeout(() => document.getElementById('spinner').scrollIntoView({ behavior: 'smooth' }));
        this.searchError = null;
    }

    onInputFocus() {
        this.showSearch = true;
        const inputId = this.isMobileDevice ? 'searchInputMobile' : 'searchInputDesktop';
        this.amplitudeService.logEvent(
            AMPLITUDE_EVENT_CATEGORIES.Engagement,
            AMPLITUDE_EVENTS.SEARCH_CLICKS_ON_SEARCH_ICON,
        );
        this.isProcessingDocs = false;
        setTimeout(() => document.getElementById(inputId).focus(), 0);
    }

    fetchHelpDocs() {
        this.isProcessingDocs = true;
        this.helpDocsRequest = this.helpDocsService
            .getList(1, 3, { query: this.searchText })
            .pipe(
                catchError(() => {
                    // @ts-ignore
                    this.helpDocResults = this.helpDocsStatic;
                    return EMPTY;
                }),
                tap(() => (this.isProcessingDocs = false)),
                switchMap(({ objects }) => {
                    // @ts-ignore
                    this.helpDocResults = objects.length ? objects : this.helpDocsStatic;
                    this.helpDocResultsCache.set(this.searchText, this.helpDocResults);
                    return of(null);
                }),
                finalize(() => (this.isProcessingDocs = false)),
            )
            .subscribe();
    }

    onSearch(value: string, clearPreviousResponse: boolean = false) {
        if (!value || !this.searchText) {
            return;
        }

        this.searchError = null;
        this.isProcessingSearch = true;
        if (!this.seeMoreClicked) {
            this.fetchHelpDocs();
        }

        let commonParams = `&page_size=4&sort=${this.sortBy.value}`;
        if (this.filterBy.value !== 'all') {
            commonParams += `&filter=${this.filterBy.value}`;
        }

        const requestBody = {
            query: value,
            search_after: undefined
        };
        if (this.seeMoreClicked && this.searchResults.length) {
            const last_result = this.searchResults[this.searchResults.length - 1];
            requestBody.search_after = last_result?.sort;
        }

        const scrollString = `?${commonParams}`;

        this.searchRequest = this.searchService
            .post(requestBody, scrollString)
            .pipe(
                catchError(() => {
                    this.searchError = 'An error occurred while searching your query';
                    return EMPTY;
                }),
                tap(() => (this.isProcessingSearch = false)),
                switchMap((response) => {
                    const { results, total } = response;
                    if (!total) {
                        this.filterBy = { name: 'All', value: 'all' };
                        this.sortBy = { name: 'Last Updated', value: '-updated' };
                    }

                    const searchResults = results
                        .map((item) => {
                            if (!this.authService.getOrgByID(item['organization']?.id)) {
                                this.authService.appendOrgToList(item['organization']);
                            }
                            if (item?.object_type !== 'label') {
                                return new SearchItem(item);
                            }
                            return undefined;
                        })
                        .filter(Boolean);

                    if (clearPreviousResponse) {
                        this.searchResults = [];
                        this.totalSearchResults = 0;
                    }
                    this.totalSearchResults = total;

                    this.searchResults = this.seeMoreClicked ? this.searchResults.concat(searchResults) : searchResults;
                    this.hasFirstSearchDone = true;
                    return of(null);
                }),
                finalize(() => (this.isProcessingDocs = false)),
            )
            .subscribe();
    }

    onSelectSearchItem(item) {
        Utils.switchOrganization(item.organization.id, this.authService);
        this.recentSearches.unshift(this.searchText);
        const searchesSet = new Set(this.recentSearches);
        this.recentSearches = Array.from(searchesSet);
        this.recentSearches.splice(3);
        this.clearSearch();
    }

    onClickRecentSearch(recentSearch) {
        this.searchText = recentSearch;
        this.isProcessingSearch = true;
        this.isProcessingDocs = true;
        this.searchResults = [];
        this.onSearchInput();
    }

    clearSearch(fromClearIcon: boolean = false) {
        if (this.isMobileDevice && !this.searchText && fromClearIcon) {
            this.onToggleSearchBar.emit();
        }
        this.searchText = '';
        this.showSearch = false;
        this.clearSearchResponse();
    }

    onSelectFilterOption(filterOption) {
        this.filterBy = filterOption;
        this.triggerSearch();
        this.amplitudeService.logEvent(AMPLITUDE_EVENT_CATEGORIES.Engagement, AMPLITUDE_EVENTS.SEARCH_ON_FILTERS, {
            filterBy: filterOption.name,
        });
    }

    onSelectSortByOption(sortByOption) {
        this.sortBy = sortByOption;
        this.triggerSearch();
        this.amplitudeService.logEvent(AMPLITUDE_EVENT_CATEGORIES.Engagement, AMPLITUDE_EVENTS.SEARCH_ON_FILTERS, {
            filterBy: sortByOption.name,
        });
    }

    toggleFilterByDropdown($event) {
        this.isSortByDropdownOpen = false;
        this.isFilterByDropdownOpen = !this.isFilterByDropdownOpen;
        this.amplitudeService.logEvent(AMPLITUDE_EVENT_CATEGORIES.Engagement, AMPLITUDE_EVENTS.SEARCH_ON_FILTERS);
        $event?.stopPropagation();
    }

    triggerSearch() {
        this.onSearch(this.searchText, true);
    }

    toggleSortByDropdown($event) {
        this.isFilterByDropdownOpen = false;
        this.isSortByDropdownOpen = !this.isSortByDropdownOpen;
        $event?.stopPropagation();
    }

    closeDropdowns($event) {
        this.isFilterByDropdownOpen = false;
        this.isSortByDropdownOpen = false;
        $event?.stopPropagation();
    }

    private clearSearchResponse() {
        this.searchError = null;
        if (this.searchRequest) {
            this.searchRequest.unsubscribe();
            this.helpDocsRequest?.unsubscribe();
        }
    }

    @HostListener('document:click', ['$event'])
    hideSearch() {
        if (this.isMobileDevice) {
            this.onToggleSearchBar.emit();
        }
        this.showSearch = false;
        this.clearSearch();
    }

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.metaKey && event.key === 'k') {
            const inputId = this.isMobileDevice ? 'searchInputMobile' : 'searchInputDesktop';
            if (!this.isMobileDevice) {
                setTimeout(() => document.getElementById(inputId).focus(), 0);
                this.showSearch = true;
            }
        }
    }

    onKeydownHandler(event: KeyboardEvent) {
        if (event.keyCode === 27) {
            this.showSearch = false;
            const inputId = this.isMobileDevice ? 'searchInputMobile' : 'searchInputDesktop';
            setTimeout(() => document.getElementById(inputId).blur(), 0);
            this.clearSearch();
        }
    }

    ngOnDestroy() {
        this.searchInputSubject.unsubscribe();
        this.searchRequest?.unsubscribe();
        this.helpDocsRequest?.unsubscribe();
    }
}
